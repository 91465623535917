@tailwind base;
@tailwind components;
@tailwind utilities;

.preview {
    position: relative;
}

* {
    margin: 0;
    padding: 0;
    font-family: "cairo", sans-serif;
}
